export const QueryKeys = {
    earnRules: 'earnRules',
    spendRules: 'spendRules',
    coupons: 'coupons',
    contact: 'contact'
};
export const MutationKeys = {
    claimReward: 'claimReward',
    claimSpendRule: 'claimSpendRule',
    joinProgram: 'joinProgram'
};
