import apiFetch from '@wordpress/api-fetch';
import { logger } from '../config/logger';
import { LocalStorageKeys } from '../config/constants';
// Stores the current nonce for the middleware.
let currentNonce = '';
let currentTimestamp = 0;
try {
    const storedNonceValue = window.localStorage.getItem(LocalStorageKeys.storeApiNonce);
    const parsedNonce = storedNonceValue
        ? JSON.parse(storedNonceValue)
        : null;
    currentNonce = parsedNonce?.nonce ?? '';
    currentTimestamp = parsedNonce?.timestamp ?? 0;
}
catch {
    // We can ignore an error from JSON parse.
}
/**
 * Returns whether or not this is a wc/store API request.
 */
const isStoreApiRequest = (options) => {
    const url = options.url ?? options.path;
    if (!url) {
        return false;
    }
    if (!options.method ?? options.method === 'GET') {
        return false;
    }
    return /wc\/store\/v1\//.exec(url) !== null;
};
/**
 * Set the current nonce from a header object.
 */
const setNonce = (headers) => {
    let nonce = null;
    let timestamp = null;
    if (headers instanceof Headers) {
        nonce = headers.get('Nonce');
        timestamp = headers.get('Nonce-Timestamp');
    }
    else if (typeof headers === 'object' && headers !== null) {
        nonce = headers.Nonce;
        timestamp = headers['Nonce-Timestamp'];
    }
    if (nonce) {
        updateNonce(nonce, timestamp);
    }
};
/**
 * Updates the stored nonce within localStorage so it is persisted between page loads.
 */
const updateNonce = (nonce, timestamp) => {
    // If the "new" nonce matches the current nonce, we don't need to update.
    if (nonce === currentNonce) {
        return;
    }
    // Only update the nonce if newer. It might be coming from cache.
    if (currentTimestamp && timestamp && Number(timestamp) < currentTimestamp) {
        return;
    }
    currentNonce = nonce;
    currentTimestamp = Number(timestamp) ?? Date.now() / 1000; // Convert ms to seconds to match php time()
    // Update the persisted values.
    window.localStorage.setItem(LocalStorageKeys.storeApiNonce, JSON.stringify({
        nonce: currentNonce,
        timestamp: currentTimestamp
    }));
};
const appendNonceHeader = (request) => {
    const headers = request.headers ?? {};
    request.headers = {
        ...headers,
        Nonce: currentNonce
    };
    return request;
};
const wcStoreApiNonceMiddleware = (options, next) => {
    if (isStoreApiRequest(options)) {
        options = appendNonceHeader(options);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (Array.isArray(options?.data?.requests)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            options.data.requests = options.data.requests.map(appendNonceHeader);
        }
    }
    return next(options);
};
const logMiddleware = (options, next) => {
    const start = Date.now();
    const result = next(options);
    result
        .then(() => {
        logger.info(`[api] ${options.path} ${Date.now() - start}ms`);
    })
        .catch((e) => {
        logger.error(`[api] ${options.path} ${Date.now() - start}ms`, e);
    });
    return result;
};
apiFetch.use(wcStoreApiNonceMiddleware);
apiFetch.use(logMiddleware);
// @see https://github.com/woocommerce/woocommerce-blocks/blob/6cd3ab745be5a07ef486eee9f4f34c1708428154/assets/js/middleware/store-api-nonce.js#L114C1-L114C30
// @ts-expect-error - This is actually available, but not typed in the package.
apiFetch.setNonce = setNonce;
updateNonce(window.piggyMiddlewareConfig.storeApiNonce, window.piggyMiddlewareConfig.wcStoreApiNonceTimestamp);
// API Wrapper
const request = async (method = 'GET', path, data, options) => {
    return apiFetch({ path, method, data, ...options })
        .then((data) => {
        return {
            data: data,
            error: null
        };
    })
        .catch((e) => {
        const err = e;
        return {
            data: null,
            error: {
                status: err?.data?.status ? err.data.status : 500,
                statusText: err ? err.code : 'unknown',
                data: err ? err.message : 'unknown'
            }
        };
    });
};
/**
 * A simple SDK wrapper around the `@wordpress/api-fetch` package.
 *
 * @see https://github.com/woocommerce/woocommerce-blocks/blob/trunk/src/StoreApi/docs
 * @see https://developer.wordpress.org/block-editor/reference-guides/packages/packages-api-fetch
 */
export const api = {
    get: (path, options) => {
        return request('GET', path, undefined, options);
    },
    post: (path, data, options) => {
        return request('POST', path, data, options);
    },
    put: (path, data, options) => {
        return request('PUT', path, data, options);
    },
    delete: (path, options) => {
        return request('DELETE', path, undefined, options);
    }
};
