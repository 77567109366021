const ACTION_HOOK_NAMES = {
    'on.init': 'on.init'
};
const NAMESPACE = 'piggy';
export const hooks = {
    doAction: (hookname, ...args) => {
        window.wp.hooks.doAction(hookname, ...args);
    },
    addAction: (hookname, callback, priority) => {
        window.wp.hooks.addAction(hookname, NAMESPACE, callback, priority);
    },
    doingAction: (hookname) => {
        return window.wp.hooks.doingAction(hookname);
    }
};
