import { isDev } from './environment';
const currentTimeString = () => {
    const now = new Date();
    return `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}:${now.getMilliseconds()}`;
};
const info = (...args) => {
    if (isDev) {
        console.info(`[${currentTimeString()}]`, ...args);
    }
};
const debug = (...args) => {
    if (isDev) {
        console.log(`[${currentTimeString()}]`, ...args);
    }
};
const log = (...args) => {
    if (isDev) {
        console.log(`[${currentTimeString()}]`, ...args);
    }
};
const warn = (...args) => {
    if (isDev) {
        console.warn(`[${currentTimeString()}]`, ...args);
    }
};
const error = (...args) => {
    if (isDev) {
        console.error(`[${currentTimeString()}]`, ...args);
    }
};
export const logger = {
    debug,
    info,
    log,
    warn,
    error
};
