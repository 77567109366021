import { api } from '../../api';
import { camelCase, mapKeys } from 'lodash-es';
export class WooCommerceStoreApiError extends Error {
    status;
    statusText;
    data;
    message;
    constructor(status, statusText, data) {
        super(`WooCommerceStoreApiError: ${statusText}`);
        this.status = status;
        this.statusText = statusText;
        this.data = data;
        this.message = data;
    }
}
export class CartApiService {
    formatCartResponse(response) {
        return mapKeys(response, (_, key) => camelCase(key));
    }
    async getCartData() {
        const { data, error } = await api.get('/wc/store/v1/cart', {
            cache: 'no-store'
        });
        if (error ?? !data) {
            if (error) {
                throw new WooCommerceStoreApiError(error.status, error.statusText, error.data);
            }
            throw new Error('No data returned');
        }
        return this.formatCartResponse(data);
    }
    async removeCartItem(key) {
        const { data, error } = await api.post(`/wc/store/v1/cart/remove-item`, {
            key
        }, {
            cache: 'no-store'
        });
        if (error ?? !data) {
            if (error) {
                throw new WooCommerceStoreApiError(error.status, error.statusText, error.data);
            }
            throw new Error('No data returned');
        }
        return this.formatCartResponse(data);
    }
    async addCartItem({ id, quantity, variation, context }) {
        const { data, error } = await api.post('/wc/store/v1/cart/add-item', {
            id,
            quantity,
            variation,
            piggy: {
                context: context
            }
        }, {
            cache: 'no-store'
        });
        if (error ?? !data) {
            if (error) {
                throw new WooCommerceStoreApiError(error.status, error.statusText, error.data);
            }
            throw new Error('No data returned');
        }
        return this.formatCartResponse(data);
    }
    async addCartItemsBatch(items) {
        const requests = items.map((item) => ({
            path: '/wc/store/v1/cart/add-item',
            method: 'POST',
            cache: 'no-store',
            body: {
                id: item.id,
                quantity: item.quantity,
                variation: item.variation,
                piggy: {
                    context: item.context
                }
            }
        }));
        const { data, error } = await api.post('/wc/store/v1/batch', { requests });
        if (error ?? !data) {
            if (error) {
                throw new WooCommerceStoreApiError(error.status, error.statusText, error.data);
            }
            throw new Error('No data returned');
        }
        // Use the last response as the cart data.
        return this.formatCartResponse(data.responses[data.responses.length - 1].body);
    }
    async updateCartItemQuantity({ key, quantity }) {
        const { data, error } = await api.post('/wc/store/v1/cart/update-item', { key, quantity }, {
            cache: 'no-store'
        });
        if (error ?? !data) {
            if (error) {
                throw new WooCommerceStoreApiError(error.status, error.statusText, error.data);
            }
            throw new Error('No data returned');
        }
        return this.formatCartResponse(data);
    }
    async addCoupon(couponCode) {
        const { data, error } = await api.post(`/wc/store/v1/cart/apply-coupon`, {
            code: couponCode
        }, {
            cache: 'no-store'
        });
        if (error ?? !data) {
            if (error) {
                throw new WooCommerceStoreApiError(error.status, error.statusText, error.data);
            }
            throw new Error('No data returned');
        }
        return this.formatCartResponse(data);
    }
    async removeCoupon(couponCode) {
        const { data, error } = await api.post(`/wc/store/v1/cart/remove-coupon`, {
            code: couponCode
        }, {
            cache: 'no-store'
        });
        if (error ?? !data) {
            if (error) {
                throw new WooCommerceStoreApiError(error.status, error.statusText, error.data);
            }
            throw new Error('No data returned');
        }
        return this.formatCartResponse(data);
    }
}
export const cartApiService = new CartApiService();
